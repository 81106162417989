import {createRouter, createWebHashHistory} from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/down',
        name: 'down',
        component: () => import(/* webpackChunkName: "about" */ '../views/DownView.vue')
    }, {
        path: '/policy',
        name: 'policy',
        component: () => import(/* webpackChunkName: "about" */ '../views/PolicyView.vue')
    },
    {
        path: '/about',
        name: 'about',
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router;
