<template>
    <div class="home">
        <img src="@/assets/home.png" alt="" class="viewBg" @click="handleJump">
    </div>
</template>

<script>
import router from "@/router";

export default {
    name: 'HomeView',
    components: {},
    methods: {
        handleJump() {
            router.push("/down");
        },
    }
};
</script>

<style lang="scss" scoped>
.home {
    background: #000;
}
</style>
