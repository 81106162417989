<script setup>
import {useRouter} from "vue-router";

const router = useRouter();

const handleJump = (route) => {
    router.push(route);
};
</script>

<template>
    <div class="wrap">
        <img src="@/assets/logo.png" alt="" class="logo">
        <nav>
            <div class="item" :class="{active:router.currentRoute.value.name === 'home'}" @click="handleJump('/')">Home ·</div>
            <div class="item" :class="{active:router.currentRoute.value.name === 'down'}" @click="handleJump('/down')">Download ·</div>
            <div class="item" :class="{active:router.currentRoute.value.name === 'about'}" @click="handleJump('/about')">About US ·</div>
            <div class="item" :class="{active:router.currentRoute.value.name === 'policy'}" @click="handleJump('/policy')">Terms &Policy ·</div>
        </nav>
    </div>
</template>

<style scoped lang="scss">
.wrap {
    position: absolute;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 1400px;
    margin: 0 auto;
    padding: 25px 0;
    z-index: 1000;

    .logo {
        width: 56px;
        height: 42px;
        margin-right: 800px;
    }

    nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 1354px;
        padding: 15px 136px;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 70px;

        .item {
            margin-right: 10px;
            font-size: 18px;
            color: #fff;
            line-height: 25px;
            cursor: pointer;
            white-space: nowrap;
        }

        .active {
            background-image: linear-gradient(to right, #43AAFF, #A9EEFF);
            color: transparent;
            background-size: cover;
            background-position: center;
            background-clip: text;
        }

        .appName {
            height: 24px;
        }
    }
}
</style>